<template>
  <VContent>
    <template slot="right">
      <h6>عن مصرفنا</h6>
    </template>
    <template slot="left">
      <b-button size="sm" variant="success" class="ml-3" @click="update()"
        >تحديث</b-button
      >
    </template>
    <template slot="content">
      <VTextEditor v-model="form.text"></VTextEditor>
    </template>
  </VContent>
</template>

<script>
import { clearObject, successAlert, errorAlert } from "@/utils/helper";

export default {
  computed: {
    form: {
      get() {
        return this.$store.state.admin.about.form;
      },
      set(value) {
        this.$store.commit("admin/about/SET_TEXT", value);
      },
    },
  },
  mounted() {
    this.$store.dispatch("admin/about/get");
  },
  methods: {
    update() {
      this.$store
        .dispatch("admin/about/update")
        .then((response) => {
          successAlert();
          this.$store.commit("admin/about/SET_ABOUT", response.payload);
        })
        .catch((error) =>
          errorAlert(
            error.response ? error.response.data.message : "حدثت مشكله"
          )
        );
    },
    clearForm() {
      this.form = clearObject(this.form);
      this.$emit("close");
    },
  },
};
</script>
<style></style>
